import { GetStaticProps } from 'next'
import { ComponentProps } from 'react'

import { get404Slug } from '../utils/env'
import CmsPage, { getStaticProps as baseGetStaticProps } from './[[...slugs]]'

type Props = ComponentProps<typeof CmsPage>

export const getStaticProps: GetStaticProps<Props> = async (context) =>
  baseGetStaticProps({
    ...context,
    params: {
      slugs: get404Slug(),
    },
  })

const ErrorPage404 = (props: Props) => (
  <CmsPage {...props} canonical={false} alternates={false} />
)

export default ErrorPage404
